<template>
  <v-app class="lato-font">
    <div class="row marg-0">
      <div class="col-md-7 col-sm-12 col-12 padd-r-40 padd-l-0 padd-t-128 padd-r-0-mob">
        <div class="row marg-0 align-center">
          <span class="fsize24 clr-000000">Pay account opening fee</span>
        </div>
        <div class="bordercolor margin-t-20" id="equtitySelect">
          <div class="padd-22 backgroundclr">
            <span class="checkbox-center">
              <input
                type="checkbox"
                class="h-18 w-18 accept"
                id="select-all"
                value=""
                @change="equity($event)"
                name="all"
            /></span>
            <span class="fsize16-000 fw-bold margin-l-6">Equity</span>
            <span class="f-right marg-r-14 fw-bold clr-000000"
              ><span class="fsize18">&#8377;</span>200</span
            >
          </div>
          <div class="padding-12-14-22 fsize14 clr-000000">
            Buy and sell Stocks, Mutual Funds, and ETFS on NSE and BSE
          </div>
          <div class="padd-l-22-b-20">
            <span class="checkbox-center">
              <input
                type="checkbox"
                class="h-18 w-18 accept"
                id="bike"
                value=""
                @change="equity1($event)"
                name="vehicle"
            /></span>
            <span class="fsize14 margin-l-6 padd0-14">NSE</span>
            <span class="marg-l-14 checkbox-center">
              <input
                type="checkbox"
                class="h-18 w-18 accept"
                id="car"
                value=""
                @change="equity2($event)"
                name="vehicle"
            /></span>
            <span class="fsize14 margin-l-6 padd0-14">BSE</span>
          </div>
        </div>

        <div class="margin-t-20">
          <div class="bordercolor">
            <div class="padd-22 backgroundclr">
              <span class="checkbox-center">
                <input
                  type="checkbox"
                  class="h-18 w-18 commoditymcx"
                  id="commodityall"
                  @change="checkCommodity1($event)"
                  value=""
              /></span>
              <span class="fsize16-000 fw-bold margin-l-6">Commodity</span>
              <span class="f-right marg-r-14 fw-bold clr-000000"
                ><span class="fsize18">&#8377;</span>100</span
              >
            </div>
            <div class="padding-12-14-22 fsize14 clr-000000">
              Buy and Sell Commodities in MCX
            </div>
            <div class="padd-l-22-b-20">
              <span class="checkbox-center">
                <input
                  type="checkbox"
                  class="h-18 w-18 commoditymcx"
                  id=""
                  value=""
                  @change="checkCommodity($event)"
                  name="mcx"
              /></span>
              <span class="fsize14 margin-l-6 padd0-14">MCX</span>
            </div>
          </div>
        </div>

        <div class="margin-t-20">
          <div class="bordercolor">
            <div class="padd-22 backgroundclr">
              <span class="checkbox-center">
                <input
                  type="checkbox"
                  class="h-18 w-18 futureoption"
                  id="features-all"
                  @change="checkCDS3($event)"
                  value=""
              /></span>
              <span class="fsize16-000 fw-bold margin-l-6"
                >Futures & Options</span
              >
              <!-- <span class="f-right marg-r-14">$200</span> -->
            </div>
            <div class="padding-12-14-22 fsize14 clr-000000">
              Buy and sell Derivatives Contracts on NFO, BFO, CDS
            </div>

            <div class="padd-l-22-b-20">
              <span class="checkbox-center">
                <input
                  type="checkbox"
                  class="h-18 w-18 futureoption"
                  id="vehicle1"
                  value=""
                  @change="checkCDS2($event)"
                  name="feature-checkbox"
              /></span>
              <span class="fsize14 margin-l-6 padd0-14">NFO</span>
              <span class="marg-l-14 checkbox-center">
                <input
                  type="checkbox"
                  class="h-18 w-18 futureoption"
                  id="vehicle1"
                  value=""
                  @change="checkCDS1($event)"
                  name="feature-checkbox"
              /></span>
              <span class="fsize14 margin-l-6 padd0-14">BFO</span>

              <span class="marg-l-14 checkbox-center">
                <input
                  type="checkbox"
                  class="h-18 w-18 futureoption"
                  id="vehicle1"
                  value=""
                  @change="checkCDS($event)"
                  name="feature-checkbox"
              /></span>
              <span class="fsize14 margin-l-6 padd0-14">CDS</span>
            </div>
          </div>
        </div>

        <div class="margin-t-46 margin-b-50">
          <div class="bordercolor">
            <div class="padd-22 backgroundclr">
              <span class="fsize16-000 fw-bold margin-l-6">Total</span>
              <span class="f-right marg-r-14 fw-bold clr-00b894">
                <span class="fsize18">&#8377;</span>300</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-12  padd-0 padd-0-40 backgroundclr-efef padd-t-1-mob">
        <div class="marg-t-40">
          <span class="fsize14 fw-bold clr-000000">Pay using</span>
        </div>
        <div class="marg-t-18">
          <span class="checkbox-center">
            <input
              type="radio"
              id="male"
              name="gender"
              value="male"
              checked
              class="h-18 w-18"
            />
          </span>
          <label class="marg-l-8 marg-0 w-46 fsize14">UPI</label>
          <span class="checkbox-center">
            <input
              type="radio"
              id="female"
              name="gender"
              value="female"
              class="h-18 w-18"
            />
          </span>
          <span class="marg-l-8 fsize14">Card/Netbanking/wallet</span>
          <div class="margin-t-20">
            <input
              type="text"
              class="w-293 h-40 bor-radius padd-8-16 border-input fsize14 box-shado backgroundclr-fff w-100per"
              placeholder="Your UPI handle. Eg: yourname@hdfc.com"
              autocomplete="off"
            />
          </div>
          <div class="marg-t-18">
            <p class="fsize12">
              <span>online account opening requires your mobile number</span>
              <label class="marg-0"
                >to be linked to Aadhaar. You can check if your mobile </label
              ><label class="marg-0">
                number is linked to Aadhaar
                <span class="clr-0073bc">here</span>. if your mobile </label
              ><label class="marg-0">
                number isn't linked to Aadhaar, please open your </label
              ><label class="marg-0">
                account offline.
                <span class="clr-0073bc">know more</span>.</label
              >
            </p>
          </div>
          <div class="margin-t-20">
            <!-- <router-link
              to="/aadhaarkyc"
              active-class="active"
              tag="button"
              exact
              class="btn-color fsize18 padd-0-16 bor-radius h-48"
            >
              Pay & Continue</router-link
            > -->
            <button
              class="btn-color fsize18 padd-0-16 bor-radius h-40 w-100per"
              @click="sendotp()"
            >
              Pay & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
// import  $ from "jquery"

export default {
  data() {
    return {
      show_btn: false,
    };
  },
  methods: {
    equity(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },
    equity1(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },
    equity2(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },
    checkCommodity(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },
    checkCommodity1(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },

    checkCDS(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },
    checkCDS1(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },
    checkCDS2(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },
    checkCDS3(evt) {
      console.log(evt);
      const checked = evt.target.checked;
      console.log(checked);
      if (checked == true) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
    },
    sendotp() {
      // if (this.isCheckedTerms || this.isCheckedTerms1 || this.isCheckedTerms2) {
      //Error catch user id is invalid//
      if (this.show_btn == true) {
        this.$router.push("/aadhaarkyc");
      } else {
       
      }
    },
  },

  mounted() {
    //  equity checkbox select all
    document.getElementById("select-all").onclick = function () {
      var checkboxes = document.getElementsByName("vehicle");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };

    //commodity checkbox select all
    document.getElementById("commodityall").onclick = function () {
      var checkboxes = document.getElementsByName("mcx");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };

    //   Features & options checkbox select all
    document.getElementById("features-all").onclick = function () {
      var checkboxes = document.getElementsByName("feature-checkbox");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
  },
};
</script>


<style>
</style>